import TurboAdmin from './class-turbo-admin.js';
import wpItems from './wp-items.js';

const defaultShortcutKey = {
    meta: false,
    alt: true,
    ctrl: true,
    shift: true,
    key: 'p'
}

if (navigator.platform.startsWith('Mac')) {
    defaultShortcutKey.meta = true;
    defaultShortcutKey.ctrl = false;
}

const defaultSettings = {
    shortcutKeys: [
        defaultShortcutKey
    ],
    appendToElement: '#preview-container',
    extraItemsRaw: wpItems,
    extraItems: [
        {
            'detectType': 'dom',
            'detectSelector': 'body',
            'itemTitleFunction': () => { return atob('RnJlZSBsaWNlbnNlcyE=') },
            'itemUrlFunction': () => { return 'javascript:alert(\'Oooh, almost!! Keep trying!\')' },
        },
    ]
}

async function taInit(settings) {
    globalThis.turboAdminOptions = defaultSettings;

    globalThis.turboAdmin = new TurboAdmin(globalThis.turboAdminOptions);

    setTimeout(() => globalThis.turboAdmin.turboAdminPalette.showPalette(), 1500);
}

// I used to do this on DOMContentLoaded, but now I'm injecting from the
// background script on page completed, I don't need that hook.

taInit();

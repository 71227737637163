export default wpItems = [
    {
        "title": "Buy Turbo Admin Extension",
        "action": "#the-deal",
        "parentTitle": ""
    },
    {
        "title": "Get Free Turbo Admin Plugin",
        "action": "https://wordpress.org/plugins/turbo-admin/",
        "parentTitle": ""
    },
    {
        "title": "Dashboard",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Home",
        "action": "javascript:return;",
        "parentTitle": "Dashboard"
    },
    {
        "title": "Updates <span class=\"update-plugins count-3\"><span class=\"update-count\">3</span></span>",
        "action": "javascript:return;",
        "parentTitle": "Dashboard"
    },
    {
        "title": "Posts",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "All Posts",
        "action": "javascript:return;",
        "parentTitle": "Posts"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Posts"
    },
    {
        "title": "Categories",
        "action": "javascript:return;",
        "parentTitle": "Posts"
    },
    {
        "title": "Tags",
        "action": "javascript:return;",
        "parentTitle": "Posts"
    },
    {
        "title": "Analytics",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Media",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Library",
        "action": "javascript:return;",
        "parentTitle": "Media"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Media"
    },
    {
        "title": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Forms",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "New Form",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "Entries",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "Settings",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "Import/Export",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "Add-Ons",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "System Status",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "Help",
        "action": "javascript:return;",
        "parentTitle": "Forms<span title=\"Update Available\" class=\"update-plugins count-1\"><span class=\"update-count\">1</span></span>"
    },
    {
        "title": "Pages",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "All Pages",
        "action": "javascript:return;",
        "parentTitle": "Pages"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Pages"
    },
    {
        "title": "Comments <span class=\"awaiting-mod count-2\"><span class=\"pending-count\" aria-hidden=\"true\">2</span><span class=\"comments-in-moderation-text screen-reader-text\">2 Comments in moderation</span></span>",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Pins",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "All Pins",
        "action": "javascript:return;",
        "parentTitle": "Pins"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Pins"
    },
    {
        "title": "Pinboard Tags",
        "action": "javascript:return;",
        "parentTitle": "Pins"
    },
    {
        "title": "Maker Log",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "All Logs",
        "action": "javascript:return;",
        "parentTitle": "Maker Log"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Maker Log"
    },
    {
        "title": "Appearance",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Themes",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Customise",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Widgets",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Menus",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Header",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Background",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Templates",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Template Parts",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Header",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Background",
        "action": "javascript:return;",
        "parentTitle": "Appearance"
    },
    {
        "title": "Plugins <span class=\"update-plugins count-3\"><span class=\"plugin-count\">3</span></span>",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Installed Plugins",
        "action": "javascript:return;",
        "parentTitle": "Plugins <span class=\"update-plugins count-3\"><span class=\"plugin-count\">3</span></span>"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Plugins <span class=\"update-plugins count-3\"><span class=\"plugin-count\">3</span></span>"
    },
    {
        "title": "Users",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "All Users",
        "action": "javascript:return;",
        "parentTitle": "Users"
    },
    {
        "title": "Add New",
        "action": "javascript:return;",
        "parentTitle": "Users"
    },
    {
        "title": "Profile",
        "action": "javascript:return;",
        "parentTitle": "Users"
    },
    {
        "title": "Tools",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Available Tools",
        "action": "javascript:return;",
        "parentTitle": "Tools"
    },
    {
        "title": "Import",
        "action": "javascript:return;",
        "parentTitle": "Tools"
    },
    {
        "title": "Export",
        "action": "javascript:return;",
        "parentTitle": "Tools"
    },
    {
        "title": "Site Health",
        "action": "javascript:return;",
        "parentTitle": "Tools"
    },
    {
        "title": "Export Personal Data",
        "action": "javascript:return;",
        "parentTitle": "Tools"
    },
    {
        "title": "Erase Personal Data",
        "action": "javascript:return;",
        "parentTitle": "Tools"
    },
    {
        "title": "Settings",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "General",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Writing",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Reading",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Discussion",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Media",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Permalinks",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Privacy",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "Sync Pinboard",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "WP Quick Image",
        "action": "javascript:return;",
        "parentTitle": "Settings"
    },
    {
        "title": "SEO <span class=\"update-plugins count-1\"><span class=\"plugin-count\" aria-hidden=\"true\">1</span><span class=\"screen-reader-text\">1 notification</span></span>",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "General",
        "action": "javascript:return;",
        "parentTitle": "SEO <span class=\"update-plugins count-1\"><span class=\"plugin-count\" aria-hidden=\"true\">1</span><span class=\"screen-reader-text\">1 notification</span></span>"
    },
    {
        "title": "Search Appearance",
        "action": "javascript:return;",
        "parentTitle": "SEO <span class=\"update-plugins count-1\"><span class=\"plugin-count\" aria-hidden=\"true\">1</span><span class=\"screen-reader-text\">1 notification</span></span>"
    },
    {
        "title": "Social",
        "action": "javascript:return;",
        "parentTitle": "SEO <span class=\"update-plugins count-1\"><span class=\"plugin-count\" aria-hidden=\"true\">1</span><span class=\"screen-reader-text\">1 notification</span></span>"
    },
    {
        "title": "Tools",
        "action": "javascript:return;",
        "parentTitle": "SEO <span class=\"update-plugins count-1\"><span class=\"plugin-count\" aria-hidden=\"true\">1</span><span class=\"screen-reader-text\">1 notification</span></span>"
    },
    {
        "title": "Premium",
        "action": "javascript:return;",
        "parentTitle": "SEO <span class=\"update-plugins count-1\"><span class=\"plugin-count\" aria-hidden=\"true\">1</span><span class=\"screen-reader-text\">1 notification</span></span>"
    },
    {
        "title": "Gutenberg",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Demo",
        "action": "javascript:return;",
        "parentTitle": "Gutenberg"
    },
    {
        "title": "Support",
        "action": "javascript:return;",
        "parentTitle": "Gutenberg"
    },
    {
        "title": "Documentation",
        "action": "javascript:return;",
        "parentTitle": "Gutenberg"
    },
    {
        "title": "Experiments",
        "action": "javascript:return;",
        "parentTitle": "Gutenberg"
    },
    {
        "title": "Security",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Settings",
        "action": "javascript:return;",
        "parentTitle": "Security"
    },
    {
        "title": "Security Check",
        "action": "javascript:return;",
        "parentTitle": "Security"
    },
    {
        "title": "Dashboard",
        "action": "javascript:return;",
        "parentTitle": "Security"
    },
    {
        "title": "Logs",
        "action": "javascript:return;",
        "parentTitle": "Security"
    },
    {
        "title": "View/visit site",
        "action": "javascript:return;",
        "parentTitle": ""
    },
    {
        "title": "Logout",
        "action": "javascript:return;",
        "parentTitle": ""
    }
]
